import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './MyNavbar.css';
import logo from './assets/logo.png';

function MyNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
      <Navbar.Brand href="/">
      <img
          src={logo}
          width="auto" 
          height="30" // Bootstrap default height for navbar brand images
          className="d-inline-block align-top"
          alt="LaugHub Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/">Forside</Nav.Link>
          <Nav.Link href="/scoreboard">Scoreboard</Nav.Link>
          <Nav.Link href="/klrating">KL-ranking</Nav.Link>
          <Nav.Link href="/bilder">Bilder</Nav.Link>
          <Nav.Link href="/minprofil">Min profil</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
 
export default MyNavbar;

