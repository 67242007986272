import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { app } from './firebase-config';
import './Login.css';

const auth = getAuth(app);
const db = getFirestore(app);

const Login2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
          const userName = prompt("Skriv inn ditt fornavn:");
          if (userName) {
            await setDoc(userDocRef, {
              name: userName,
              points: 0,
              attended_events: []
            });
          }
        }
        
        const userData = (await getDoc(userDocRef)).data();
        localStorage.setItem('userName', userData.name);
        localStorage.setItem('userPoints', userData.points);
        navigate('/mysettings');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log('Google sign-in successful');
    } catch (error) {
      console.error('Error with Google sign-in: ', error);
    }
  };

  return (
    <div>
      <h2>Logg inn</h2>
      <button className="button-17" onClick={handleGoogleSignIn}>Logg inn med Google</button>
    </div>
  );
};

export default Login2;